<template>
  <div id="mail-section">
    <span class="title">NFT 소식을 받아보세요!</span>
    <span class="sub-title"
      >구독을 눌러주시면,곧 발매 예정인 젤리크루 스토어</span
    >
    <span class="sub-title">NFT 소식을 전해드릴게요:)</span>
    <div class="container">
      <div class="input-container">
        <input
          type="text"
          placeholder="소식을 받아볼 메일을 남겨주세요!"
          v-model="email"
          v-on:keyup.enter="register"
        />
      </div>
      <button class="register-button" @click="register">
        젤리크루 NFT 구독하기
      </button>
    </div>
  </div>
</template>

<script>
import { registerStibee } from "../../common/api.service";

export default {
  name: "MailSection",
  data() {
    return { email: "" };
  },
  methods: {
    async register() {
      if (!this.email) {
        alert("이메일을 입력해 주세요.");
        return;
      }

      const res = await registerStibee(this.email);

      if (res.error) {
        alert(res.error);
        return;
      }

      alert("등록이 완료되었습니다.");
    }
  }
};
</script>

<style scoped>
#mail-section {
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#mail-section .title {
  margin-bottom: 30px;
}

#mail-section .container {
  margin-top: 50px;
}

#mail-section .container .input-container {
  width: 350px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 25px;
}

#mail-section .container .input-container input {
  width: 250px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: center;
}

#mail-section .container input::placeholder {
  color: #b7b7b7;
  font-weight: 200;
  text-align: center;
}

#mail-section .container button {
  width: 350px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ba7fd1;
  border-radius: 25px;
  border: none;
  outline: none;
  margin-top: 12px;
  color: #f2f2f2;
  font-size: 16px;
}

@media screen and (max-width: 860px) {
  #mail-section {
    margin-top: 0px;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    margin-bottom: 0px;
  }

  #mail-section .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-self: center;
    align-items: center;
  }

  #mail-section .title {
    margin-bottom: 15px;
  }

  #mail-section .container {
    margin-top: 40px;
  }

  .register-button {
    cursor: pointer;
  }
}
</style>
