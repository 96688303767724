<template>
  <div class="carousel">
    <div class="container">
      <div class="icon" />
      <div class="wrapper"></div>
      <div class="pagination-container">
        <pagination :max-count="3"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";

export default {
  name: "Carousel",
  components: {
    Pagination
  }
};
</script>

<style scoped>
.carousel {
  width: 100%;
  height: calc(100vw * 1396 / 3000);
  max-height: 670px;
  background-color: #ba7fd1;
  background-image: url("../assets/carousel/image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 140px;
  z-index: 3;
  position: relative;
}

.title {
  font-size: 49px;
  font-family: "Poppins";
  color: #f9f9f9;
  margin-bottom: 25px;
}

.description {
  font-size: 17px;
  font-weight: 300;
  color: #f9f9f9;
}

.pagination-container {
  position: absolute;
  width: 100%;
  bottom: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 860px) {
  .carousel {
    width: 100%;
    height: calc(100vw * 1059 / 750);
    max-height: initial;

    background-image: url("../assets/carousel/image_mobile.jpg");
  }

  .pagination-container {
    bottom: 25px;
  }

  .wrapper {
    padding-top: 0px;
    bottom: 70px;
    height: 100%;
    justify-content: flex-end;
  }

  .title {
    font-size: 28px;
    margin-bottom: 8px;
  }

  .description {
    font-size: 15px;
  }
}
</style>
