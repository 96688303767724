<template>
  <div class="pagination">
    <div
      v-for="item in items"
      v-bind:key="item"
      class="button"
      :class="selectIndex === item ? 'select' : ''"
      @click="onClick(item)"
    />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    index: { require: false, type: Number },
    maxCount: { require: true, type: Number },
    onClickChange: { require: true, type: Function }
  },
  data() {
    return {
      selectIndex: 0
    };
  },
  created() {
    if (this.index) this.selectIndex = this.index;
  },
  methods: {
    onClick(item) {
      this.selectIndex = item;
      if (this.onClickChange) this.onClickChange(item);
    }
  },
  computed: {
    items() {
      return Array.from(Array(this.maxCount).keys());
    }
  },
  watch: {
    index() {
      this.selectIndex = this.index;
    }
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  height: 11px;
  width: 11px;
  background: #f9f9f9;
  border-radius: 5px;
  cursor: pointer;
}

.button + .button {
  margin-left: 10px;
}

.button.select {
  width: 35px;
  height: 11px;
  border-radius: 5px;
  background: #ffe876;
}
</style>
