<template>
  <div class="home-page">
    <carousel></carousel>
    <search-section></search-section>
    <creator-section></creator-section>
    <coming-soon-section></coming-soon-section>
    <popularity-section></popularity-section>
    <take-a-look-section></take-a-look-section>
    <mail-section></mail-section>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import SearchSection from "@/components/section/SearchSection";
import CreatorSection from "@/components/section/CreatorSection";
import ComingSoonSection from "@/components/section/ComingSoonSection";
import PopularitySection from "@/components/section/PopularitySection";
import TakeALookSection from "@/components/section/TakeALookSection";
import MailSection from "@/components/section/MailSection";

export default {
  components: {
    Carousel,
    SearchSection,
    CreatorSection,
    ComingSoonSection,
    PopularitySection,
    TakeALookSection,
    MailSection
  },
  name: "Home"
};
</script>

<style>
.title {
  font-size: 32px;
  font-weight: 700;
}

.title.poppins {
  font-family: "Poppins";
}

.sub-title {
  color: #b7b7b7;
  margin-top: 5px;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media screen and (max-width: 860px) {
  .home-page {
    background-color: #f2f2f2;
    width: 100%;
    overflow: hidden;
  }
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .title {
    font-size: 20px;
  }

  .sub-title {
    font-size: 12px;
  }
}
</style>
