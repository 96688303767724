<template>
  <a class="take-a-look-button" :href="link" target="_blank">
    <img :src="getPic()" />
    <span>{{ title }}</span>
  </a>
</template>

<script>
export default {
  name: "TakeALookButton",
  props: {
    icon: { require: true, type: String },
    title: { require: true, type: String },
    link: { require: true, type: String }
  },
  methods: {
    getPic() {
      return require(`../assets/takeALook/${this.icon}`);
    }
  }
};
</script>

<style scoped>
.take-a-look-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.take-a-look-button + .take-a-look-button {
  margin-left: 45px;
}

.take-a-look-button span {
  margin-top: 16px;
  color: #b7b7b7;
  font-family: "Poppins";
  font-weight: 300;
}

@media screen and (max-width: 860px) {
  .take-a-look-button img {
    width: calc(100vw * 70 / 360);
    height: calc(100vw * 70 / 360);
  }

  .take-a-look-button + .take-a-look-button {
    margin-left: calc(100vw * 15 / 360);
  }

  .take-a-look-button span {
    font-size: 12px;
  }
}
</style>
