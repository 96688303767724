<template>
  <div class="creator-item">
    <div class="thumbnail-container">
      <img class="thumbnail" :src="profileUrl" />
      <div class="title-container">
        <span class="title ko">{{ titleKo }}</span>
        <span class="title en">{{ titleEn }}</span>
      </div>
    </div>
    <div class="menu">
      <div @click="onClickLink('nft')">
        <img class="menu-item" src="../assets/nft_icon.png" />
      </div>
      <div @click="onClickLink('sns')">
        <img class="menu-item" src="../assets/sns_icon.png" />
      </div>
      <div @click="onClickLink('brand')">
        <img class="menu-item" src="../assets/brand_icon.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { getPageInfo } from "../common/api.service";

export default {
  name: "CreatorItem",
  props: {
    pageId: { require: true, type: String },
    name: { require: false, type: String },
    profileUrl: { require: false, type: String }
  },
  data() {
    return {
      nftLink: "",
      instagramId: "",
      brandLink: "",
      linkFetched: false
    };
  },
  created() {
    this.nftLink = `${process.env.VUE_APP_CCCV_URL}/nft/user/${this.pageId}`;
  },
  methods: {
    async getPageDetail() {
      return await getPageInfo(this.pageId);
    },
    async onClickLink(type) {
      if (!this.linkFetched) {
        const res = await this.getPageDetail();

        this.instagramId = res.data[0]?.sns?.find(
          o => o.code?.toLowerCase() === "instagram"
        )?.url;
        this.brandLink = res.data[0]?.sns?.find(
          o => o.code?.toLowerCase() === "kakaoopenchat"
        )?.url;

        this.linkFetched = true;
      }

      switch (type) {
        case "nft":
          if (this.nftLink) window.open(this.generateUrl(this.nftLink));
          else alert("NFT 링크가 존재하지 않습니다.");
          break;
        case "sns":
          if (this.instagramId) {
            let snsLink = `https://www.instagram.com/${this.instagramId}`;
            window.open(snsLink);
          } else alert("SNS 링크가 존재하지 않습니다.");
          break;
        case "brand":
          if (this.brandLink) window.open(this.generateUrl(this.brandLink));
          else alert("브랜드 링크가 존재하지 않습니다.");
          break;
      }
    },
    generateUrl(url) {
      if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }

      return url;
    }
  },
  computed: {
    titleKo: function() {
      return this.name.split("-")[0];
    },
    titleEn: function() {
      return this.name.split("-")[1];
    }
  }
};
</script>

<style scoped>
.creator-item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  align-items: center;
}

.creator-item:nth-child(n + 5) {
  margin-top: 30px;
}

.creator-item .thumbnail-container {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 25px;
  margin-bottom: 15px;
}

.creator-item .thumbnail-container .title-container {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.title.ko {
  color: #f9f9f9;
  font-size: 20px;
}
.title.en {
  color: #f9f9f9;
  font-family: "Poppins";
  font-size: 12px;
}

.creator-item .thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.creator-item .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 200px;
}

.creator-item .menu .menu-item {
  cursor: pointer;
}

@media screen and (max-width: 860px) {
  .creator-item {
    width: 46%;
  }

  .creator-item .thumbnail-container {
    position: relative;
    width: calc(100vw * 150 / 360);
    height: calc(100vw * 150 / 360);
    margin-bottom: 10px;
  }

  .creator-item:nth-child(n + 5) {
    margin-top: 0px;
  }

  .creator-item:nth-child(n + 3) {
    margin-top: 20px !important;
  }

  .creator-item .menu {
    max-width: 100%;
  }

  .creator-item .menu .menu-item {
    width: calc(100vw * 40 / 360);
  }
}
</style>
