<template>
  <div id="coming-soon-section">
    <span class="desktop title poppins">Coming Soon</span>
    <span class="desktop sub-title">신규 NFT 브랜드</span>
    <div class="title-container mobile">
      <span class="title poppins">Coming Soon</span>
      <span class="sub-title">신규 NFT 브랜드</span>
    </div>
    <div class="coming-soon-container">
      <coming-soon-item
        v-for="item in items"
        v-bind:key="item.index"
        :page-id="item.description"
      />
    </div>
  </div>
</template>

<script>
import {
  COMINGSOON_CURATION_TITLE,
  getCurationListByTitle
} from "@/common/api.service";
import ComingSoonItem from "@/components/ComingSoonItem";

export default {
  name: "ComingSoonSection",
  components: {
    ComingSoonItem
  },
  data() {
    return {
      items: []
    };
  },
  created() {
    getCurationListByTitle(COMINGSOON_CURATION_TITLE).then(res => {
      if (res && res.data) {
        this.items =
          res.data.items.sort((a, b) => {
            return a.index - b.index;
          }) ?? [];
      }
    });
  }
};
</script>

<style scoped>
#coming-soon-section {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 100px;
}

.coming-soon-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 860px;
}

@media screen and (max-width: 860px) {
  #coming-soon-section {
    margin-top: 10px;
    background-color: white;
    padding-top: 35px;
    padding-bottom: 45px;
  }

  .title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-container .title {
    margin-left: 20px;
  }

  .title-container .sub-title {
    margin-right: 20px;
  }

  .coming-soon-container {
    width: 100%;
    padding-left: 20px;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
</style>
