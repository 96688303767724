<template>
  <div id="popularity-section">
    <span class="title poppins desktop">인기NFT</span>
    <div class="title-container">
      <span class="title poppins mobile">인기NFT</span>
    </div>
    <!--Desktop -->
    <div class="desktop">
      <div class="popularity-container">
        <popularity-item
          v-for="item in filteredPopularityItems"
          :key="item._id"
          :data="item"
          type="popular"
          @click.native="onClickItem(item.aergoNftTokenId)"
        />
      </div>
      <div class="general-container">
        <popularity-item
          v-for="item in filteredItems"
          :key="item._id"
          :data="item"
          @click.native="onClickItem(item.aergoNftTokenId)"
        />
      </div>
      <div class="pagination-container">
        <pagination
          :max-count="paginationMaxCount"
          :on-click-change="onClickPagination"
        ></pagination>
      </div>
    </div>
    <!--Mobile -->
    <div class="container mobile">
      <!-- 모바일 인기 NFT -->
      <div class="popularity-mobile-container">
        <carousel
          :per-page="1"
          :mouse-drag="true"
          :center-mode="true"
          :navigation-enabled="false"
          :pagination-enabled="false"
          :navigate-to="mobilePaginationIndex"
          @page-change="handleMobileCarouselChange"
        >
          <slide
            v-for="item in filteredPopularityItems"
            :key="item._id"
            @slideclick="onClickItem(item.aergoNftTokenId)"
          >
            <popularity-item :data="item" type="popular" />
          </slide>
        </carousel>
      </div>
      <div class="pagination-container">
        <pagination
          :index="mobilePaginationIndex"
          :max-count="3"
          :on-click-change="onClickMobilePopularPagination"
        ></pagination>
      </div>
      <!-- 모바일 둘러보기 -->
      <div class="title-container">
        <span class="title poppins">둘러보기</span>
        <span class="sub-title">경매중인 NFT 둘러보기</span>
      </div>
      <div id="general-mobile-container" class="general-mobile-container">
        <popularity-item
          v-for="item in filteredItems"
          :key="item._id"
          :data="item"
          type="mobile-general"
          @click.native="onClickItem(item.aergoNftTokenId)"
        />
      </div>
      <div class="pagination-container general">
        <pagination
          :index="paginationIndex"
          :max-count="paginationMaxCount"
          :on-click-change="onClickPagination"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PopularityItem from "@/components/PopularityItem";
import Pagination from "@/components/Pagination";
import SellType from "@/constants/SellType";
import { Carousel, Slide } from "vue-carousel";
import { getMainNfts, searchNfts, getNft } from "@/common/api.service";
import TouchEvent from "@/common/touchEvent.service";

export default {
  name: "PopularitySection",
  components: {
    PopularityItem,
    Pagination,
    Carousel,
    Slide
  },
  data() {
    return {
      SELLTYPE: SellType,
      popularityItems: [],
      itemsObject: {},
      maxCount: 12,
      //PopularitySectioN(Desktop : 전체, Mobile: 전체 NFT)
      paginationIndex: 0,
      paginationMaxCount: 1,
      //모바일 인기 NFT를 위함
      mobilePaginationIndex: 0,
      mobilePaginationMaxCount: 0,
      touchEvent: null
    };
  },
  created() {
    getMainNfts().then(async res => {
      if (res && res.data) {
        //NFT 상세정보 받아오기
        this.popularityItems = await Promise.all(
          res.data.map(async item => {
            //해당 API로 가져온 데이터에는 aergoNftTokenid가 존재하지 않는다.
            //팔아야 하는 곳으로 이동하고, 정보를 가져와야 되기 때문에 creator_id와 owner_id가 다른 토큰을 찾아서 API 조회하자
            let tokenId = "";
            item.nftTokens?.forEach(token => {
              if (token.owner_id === item.creator_id) {
                tokenId = `${item.aergoGroupId}_${token.index + 1}`;
              }
            });

            const result = await getNft(tokenId);
            if (result && result.data) {
              //클릭시 이동을 위해 aergoNftTokenId도 넣어주자
              return { ...item, aergoNftTokenId: tokenId, detail: result.data };
            }

            return item;
          })
        );
      }
    });

    searchNfts("", this.maxCount).then(async res => {
      if (res && res.data && res.data.isSuccess) {
        //페이지네이션이 존재할 경우 maxCount를 하나씩 늘려준다(cursor 방식이라 클릭했을 때 pagination 아이콘을 하나씩 늘려줘야 함)
        this.paginationMaxCount += 1;

        //NFT 상세정보 받아오기
        this.itemsObject = {
          ...res.data.results,
          paginationData: await this.getSearchNftDetail(
            res.data.results.paginationData
          )
        };
      }
    });
  },
  mounted() {
    //모바일 둘러보기 영역도 swipe가 필요하므로 swipe 이벤트 확인해서 처리하자
    //transition 추가 요청이 들어오면 carousel로 변경 예정
    const mobileContainer = document.getElementById("general-mobile-container");
    mobileContainer.addEventListener("touchstart", this.handleSwipeStart);
    mobileContainer.addEventListener("touchend", this.handleSwipeEnd);
  },
  beforeDestroy() {
    const mobileContainer = document.getElementById("general-mobile-container");
    mobileContainer.removeEventListener("touchstart", this.handleSwipeStart);
    mobileContainer.removeEventListener("touchend", this.handleSwipeEnd);
  },
  methods: {
    onClickItem(aergoNftTokenId) {
      window.open(`${process.env.VUE_APP_CCCV_URL}/nft/${aergoNftTokenId}`);
    },
    onClickMobilePopularPagination(data) {
      this.mobilePaginationIndex = data;
    },
    handleMobileCarouselChange(idx) {
      this.mobilePaginationIndex = idx;
    },
    handleSwipeStart(e) {
      this.touchEvent = new TouchEvent(e);
    },
    handleSwipeEnd(e) {
      if (!this.touchEvent) {
        return;
      }

      this.touchEvent.setEndEvent(e);

      if (this.touchEvent.isSwipeRight()) {
        if (this.paginationIndex > 0) {
          this.onClickPagination(this.paginationIndex - 1);
        }
      } else if (this.touchEvent.isSwipeLeft()) {
        if (this.paginationIndex <= 0) {
          this.onClickPagination(this.paginationIndex + 1);
        }
      }

      // Reset event for next touch
      this.touchEvent = null;
    },
    onClickPagination(data) {
      //제일 마지막 버튼을 눌렀을 경우 cursor가 더 있는지 체크하여 있을 경우 다음 데이터를 받아온다.
      if (this.paginationMaxCount - 1 === data) {
        searchNfts("", this.maxCount, this.itemsObject.nextCursor).then(
          async res => {
            if (res && res.data && res.data.results) {
              //페이지네이션이 더 존재할 경우 pagination 할 수 있는 아이콘을 더 보여주기 위해 하나씩 추가한다.
              if (res.data.results.hasNext) this.paginationMaxCount += 1;

              //데이터는 기존 데이터 appending
              this.itemsObject = {
                ...res.data.results,
                paginationData: [
                  ...this.itemsObject.paginationData,
                  ...(await this.getSearchNftDetail(
                    res.data.results.paginationData
                  ))
                ]
              };
            }
          }
        );
      }

      //선택한 pagination index update
      this.paginationIndex = data;

      this.scrollToSectionTop();
    },
    generateNftLink(aergoGroupId) {
      return `${process.env.VUE_APP_CCCV_URL}/nft/${aergoGroupId}_1`;
    },
    async getSearchNftDetail(list) {
      //NFT 상세정보 받아오기
      return await Promise.all(
        list.map(async item => {
          //aergoTokenId가 valid한 token(팔아야 하는) 이라고 가정하고 API 호출 한다(확인 필요함)
          const result = await getNft(item.aergoNftTokenId);
          if (result && result.data) {
            return { ...item, detail: result.data };
          }

          return item;
        })
      );
    },
    scrollToSectionTop() {
      var el = document.querySelector("#popularity-section");
      if (el) el.scrollIntoView({ behavior: "smooth" });
    }
  },
  computed: {
    filteredPopularityItems() {
      //메인 인기 아이템은 최대 3개만 보여준다.
      return this.popularityItems.slice(0, 3);
    },
    filteredItems() {
      //나머지 nft들은 12개씩 보여준다.
      return this.itemsObject.paginationData?.slice(
        this.paginationIndex * this.maxCount,
        (this.paginationIndex + 1) * this.maxCount
      );
    }
  }
};
</script>

<style scoped>
#popularity-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 120px;
}

#popularity-section .popularity-container {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 860px;
}

#popularity-section .general-container {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 860px;
}

#popularity-section .pagination-container {
  margin-top: 50px;
  margin-bottom: 110px;
}

@media screen and (max-width: 860px) {
  #popularity-section {
    margin-top: 10px;
    padding-top: 50px;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-left: 20px;
  }

  #popularity-section .pagination-container {
    margin-top: 0px;
    margin-bottom: 70px;
  }

  #popularity-section .pagination-container.general {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .container {
    width: 100%;
  }

  .popularity-mobile-container {
  }

  .general-mobile-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
</style>
