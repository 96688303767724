<template>
  <div id="creator-section">
    <span class="desktop title poppins">Creator</span>
    <span class="desktop sub-title">크리에이터</span>
    <div class="title-container mobile">
      <span class="title poppins">Creator</span>
      <span class="sub-title">크리에이터</span>
    </div>

    <div class="creator-container">
      <creator-item
        v-for="item in filteredItems"
        v-bind:key="item._id"
        :name="item.name"
        :page-id="item.pageId"
        :profile-url="item.profileImgUrl"
      />
    </div>
    <div class="pagination-container">
      <pagination
        :max-count="paginationMaxCount"
        :on-click-change="onClickPagination"
      ></pagination>
    </div>
  </div>
</template>

<script>
import CreatorItem from "@/components/CreatorItem";
import Pagination from "@/components/Pagination";
import { getCreators } from "@/common/api.service";

export default {
  name: "CreatorSection",
  components: {
    CreatorItem,
    Pagination
  },
  data() {
    return {
      items: [],
      maxCount: 8,
      paginationIndex: 0,
      paginationMaxCount: 0
    };
  },
  created() {
    getCreators().then(res => {
      if (res && res.data) {
        this.items = res.data;
        this.paginationMaxCount = Math.ceil(this.items.length / this.maxCount);
      }
    });
  },
  methods: {
    onClickPagination(idx) {
      if (this.items.length / this.maxCount > idx) {
        this.paginationIndex = idx;
      }
    }
  },
  computed: {
    filteredItems() {
      return this.items.slice(
        this.paginationIndex * this.maxCount,
        (this.paginationIndex + 1) * this.maxCount
      );
    }
  }
};
</script>

<style scoped>
#creator-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.creator-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 860px;
  margin-top: 28px;
  flex-wrap: wrap;
}

#creator-section .pagination-container {
  width: 100%;
  margin-top: 60px;
  margin-bottom: 100px;
}

@media screen and (max-width: 860px) {
  #creator-section {
    margin-top: 10px;
    padding-top: 45px;

    padding-left: 20px;
    padding-right: 20px;
  }

  .title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .creator-container {
    justify-content: space-between;
  }
  #creator-section .pagination-container {
    margin-top: 30px;
    margin-bottom: 25px;
  }
}
</style>
