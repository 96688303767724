<template>
  <div class="popularity-item" :class="type" target="_blank">
    <img class="tag" :src="getTagImage()" />
    <div class="container">
      <img class="thumbnail" :src="data.cover_url" />
      <div class="contents-container">
        <span class="title">{{ data.title }}</span>
        <span class="creator">{{ data.creator_name }}</span>
        <span class="price" v-if="price">{{ computedPrice }}</span>
        <span class="price" v-else>-</span>
        <img class="creator-thumbnail" :src="profileImgUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import SellType from "@/constants/SellType";

export default {
  name: "PopularityItem",
  props: {
    data: { require: true, type: Object },
    type: { require: true, type: String }
  },
  data() {
    return {
      SELLTYPE: SellType,
      price: "",
      profileImgUrl: ""
    };
  },
  created() {
    this.price = this.data.detail?.paymentProduct?.price;
    this.profileImgUrl = this.data.detail?.creatorPage?.profileImgUrl;
  },
  methods: {
    getTagImage: function() {
      switch (this.data.tag) {
        case this.SELLTYPE.AUCTION:
          return require("@/assets/sellType/auction.png");
        case this.SELLTYPE.BY_LOTS:
          return require("@/assets/sellType/by_lots.png");
        case this.SELLTYPE.SOLD:
          return require("@/assets/sellType/sold.png");
        case this.SELLTYPE.EVENT:
          return require("@/assets/sellType/event.png");
        default:
          break;
      }
    }
  },
  computed: {
    computedPrice() {
      return `${this.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}₩`;
    }
  }
};
</script>

<style scoped>
.popularity-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  text-decoration: none;
  border-radius: 25px;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 100%;
  padding: 10px 10px 15px 10px;
  margin-top: 40px;
  cursor: pointer;
}

.popularity-item.popular {
  width: 260px;
  height: 360px;
  padding: 15px 15px 20px 15px;
}

.tag {
  position: absolute;
  left: -1px;
  top: 25px;
  z-index: 2;
}

.popularity-item + .popularity-item {
  margin-left: 20px;
}

.popularity-item:nth-child(4n + 1) {
  margin-left: 0px;
}

.popularity-item.popular:not(:first-child) {
  margin-left: 40px;
}

.container {
  position: relative;
  width: 180px;
  height: auto;

  margin-bottom: 15px;
}

.popularity-item .container {
  width: 100%;
}

.contents-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 16px;
}

.title {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.creator {
  color: #b7b7b7;
  font-size: 13px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 75%;

  margin-bottom: 10px;
}

.price {
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.creator-thumbnail {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.popularity-item.popular .creator-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.popularity-item.popular .thumbnail {
  width: 230px;
  height: 230px;
  object-fit: cover;
}

.thumbnail {
  position: relative;
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 20px;
}

@media screen and (max-width: 860px) {
  .popularity-item.popular {
    width: calc(100vw * 320 / 360);
    height: auto;
    margin: calc(100vw * 20 / 360);
  }

  .popularity-item.popular .thumbnail {
    width: calc(100vw * 280 / 360);
    height: calc(100vw * 280 / 360);
    max-height: calc(100vw * 280 / 360);
    object-fit: cover;
  }

  .popularity-item.mobile-general {
    display: block;
    width: calc(100vw * 150 / 360);
    height: calc(100vw * 230 / 360);
    margin: calc(100vw * 15 / 360) calc(100vw * 5 / 360) calc(100vw * 15 / 360)
      calc(100vw * 5 / 360);

    padding: calc(100vw * 10 / 360) calc(100vw * 10 / 360)
      calc(100vw * 15 / 360) calc(100vw * 10 / 360);
  }

  .popularity-item .container {
    width: calc(100vw * 280 / 360);
  }

  .contents-container {
    margin-top: calc(100vw * 20 / 360);
  }

  .popularity-item.mobile-general .container {
    width: 100%;
    height: calc(100vw * 280 / 360);
    max-height: calc(100vw * 130 / 360);
  }

  .popularity-item.mobile-general .contents-container {
    margin-top: calc(100vw * 10 / 360);
  }

  .creator {
    font-size: calc(100vw * 13 / 360);
    margin-bottom: calc(100vw * 20 / 360);
    height: calc(100vw * 16 / 360);
  }

  .popularity-item.mobile-general .creator {
    margin-bottom: calc(100vw * 10 / 360);
  }

  .price {
    font-size: calc(100vw * 17 / 360);
  }

  .popularity-item.mobile-general .price {
    font-size: calc(100vw * 10 / 360);
  }

  .thumbnail {
    width: 100%;
    height: 100%;
  }

  .creator-thumbnail {
    width: calc(100vw * 60 / 360);
    height: calc(100vw * 60 / 360);
    border-radius: calc(100vw * 30 / 360);
  }

  .popularity-item.mobile-general .creator-thumbnail {
    width: calc(100vw * 30 / 360);
    height: calc(100vw * 30 / 360);
    border-radius: calc(100vw * 15 / 360);
  }

  .tag {
    width: calc(100vw * 50 / 360);
    height: auto;
  }

  .title {
    font-size: calc(100vw * 16 / 360);
    height: calc(100vw * 20 / 360);
  }
}
</style>
