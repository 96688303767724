<template>
  <a class="coming-soon-item" @click.stop.prevent="openWindow">
    <div class="thumbnail-container">
      <img class="thumbnail" :src="img" />
      <div class="title-container">
        <span class="title ko">{{ titleKo }}</span>
        <span class="title en">{{ titleEn }}</span>
      </div>
    </div>
  </a>
</template>

<script>
import { getUserInfo } from "@/common/api.service";
export default {
  name: "ComingSoonItem",
  props: {
    pageId: { require: true, type: String }
  },
  data() {
    return {
      title: "",
      img: "",
      link: ""
    };
  },
  created() {
    getUserInfo(this.pageId).then(res => {
      if (res && res.data) {
        this.img = res.data[0]?.profileImgUrl;
        this.title = res.data[0]?.name;
        this.link = res.data[0]?.sns?.find(
          o => o.code === "kakaoOpenChat"
        )?.url;
      }
    });
  },
  methods: {
    openWindow() {
      if (!this.link) {
        alert("브랜드 링크가 존재하지 않습니다.");
        return;
      }

      window.open(this.link, "_blank");
    }
  },
  computed: {
    titleKo() {
      return this.title?.split("-")[0];
    },
    titleEn() {
      return this.title?.split("-")[1];
    }
  }
};
</script>

<style scoped>
.coming-soon-item {
  width: 160px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.title-container {
  position: absolute;
  top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title.ko {
  color: #f9f9f9;
  font-size: 18px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.title.en {
  color: #f9f9f9;
  font-family: "Poppins";
  font-size: 10px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

@media screen and (max-width: 860px) {
  .coming-soon-item {
    flex: 0 0 auto;
    width: calc(100vw * 140 / 360);
    height: calc(100vw * 160 / 360);
  }
  .coming-soon-item + .coming-soon-item {
    margin-left: 15px;
  }
}
</style>
