<template>
  <div id="take-a-look-section">
    <span class="title poppins desktop">젤리크루 살펴보기</span>
    <div class="title-container mobile">
      <span class="title poppins">살펴보기</span>
    </div>

    <div class="container">
      <take-a-look-button
        title="Jellycrew Shop"
        icon="shop_image.png"
        link="http://jellycrew.me/"
      />
      <take-a-look-button
        title="Jellycrew SNS"
        icon="sns_image.png"
        link="https://www.instagram.com/jellycrew.me/"
      />
      <take-a-look-button
        title="Company"
        icon="company_image.png"
        link="https://handhug.kr/"
      />
      <take-a-look-button
        title="Location"
        icon="location_image.png"
        link="https://jellycrew.me/shopinfo/jellystory.html"
      />
    </div>
  </div>
</template>

<script>
import TakeALookButton from "@/components/TakeALookButton";

export default {
  name: "TakeALookSection",
  components: {
    TakeALookButton
  }
};
</script>

<style scoped>
#take-a-look-section {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 110px;
  padding-bottom: 100px;
}

#take-a-look-section .container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 860px) {
  #take-a-look-section {
    margin-top: 10px;
    padding-top: 45px;
    padding-bottom: 85px;
    background-color: white;
  }

  .title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 20px;
  }
  #take-a-look-section .container {
    margin-top: 15px;
  }
}
</style>
