<template>
  <div id="search-section">
    <span class="title desktop">작품 또는 크리에이터를 검색하세요.</span>
    <div class="search-input-container">
      <img class="search-icon" src="../../assets/search.png" />
      <input
        class="text"
        placeholder="찾고 계신 NFT 작품, 크리에이터를 검색하세요."
        v-model="keyword"
        v-on:keyup.enter="onClickEnter"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchSection",
  data() {
    return {
      keyword: ""
    };
  },
  methods: {
    onClickEnter() {
      if (!this.keyword) {
        alert("검색어를 입력해 주세요.");
        return;
      }

      window.open(
        `${process.env.VUE_APP_CCCV_URL}/nft/search?keyword=${this.keyword}`,
        "_blank"
      );
    }
  }
};
</script>

<style scoped>
#search-section {
  background-color: white;
  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 165px;
}

.search-input-container {
  position: relative;
  width: 375px;
  height: 57px;
  background-color: #f2f2f2;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}

.search-input-container .search-icon {
  position: absolute;
  left: 25px;

  width: 25px;
  height: 25px;
}

.search-input-container input {
  position: absolute;
  height: 100%;
  width: calc(100% - 100px);
  left: 60px;
  outline: none;
  background-color: transparent;
  border: 0;
  color: #b7b7b7;
  font-weight: 200;
  font-size: 15px;
}

.search-input-container input::placeholder {
  color: #b7b7b7;
  font-weight: 200;
  text-align: center;
}

@media screen and (max-width: 860px) {
  #search-section {
    padding-top: 45px;
    padding-bottom: 80px;
  }
  .search-input-container {
    margin-top: 0px;
    width: calc(100% - 60px);
    left: 0px;
  }
  .search-input-container input {
    font-size: calc(100vw * 11 / 360);
  }
}
</style>
